import React from "react";
import Layout from "../components/layout";
import withUIKit from "../withUIKit";
import CustomNavbar from "../components/Custom/Navbar";
import SmallHeader from "../components/Custom/Headers/SmallHeader";
import { Container } from "reactstrap";

const Imprint = props => {
  return (
    <Layout {...props} >
      <CustomNavbar {...props} />
      <SmallHeader />
      <hr />
      <div className="section landing-section">
        <Container>
          <h2 className="text-center">Impressum</h2>
          <p>Hier steht Text</p>
        </Container>
      </div>
    </Layout>
  );
};

export default withUIKit(Imprint);
